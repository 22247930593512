export const homepage_single_article_html = `<div class="sc-gGBfsJ fnAXxl stylegrid__column">
                        <div class="sc-kgAjT ixrNzp card_wrapper article_card">
                          <div class="sc-cJSrbW dLwzxs header_wrapper">
                            <div class="sc-frDJqD ceDRQe img_wrapper">
                              <a class="sc-htpNat bUhGXt link sc-uJMKN eYExmJ styled_link article_card" href="" aria-label="Health science’s immediate future: Behind the research"><img class="sc-csuQGl jwRblM" src="XXXARTICLE_IMAGE_SRCXXX" alt="XXXARTICLE_IMAGE_SRCXXX" width="100%" height="auto"></a>
                            </div>
                            <div class="sc-kvZOFW dlNHCW card_header">
                              <a class="sc-htpNat bUhGXt link sc-uJMKN eYExmJ article_card styled_link" href="" aria-label="XXXARTICLE_TITLE_ALTXXX"><h3 title="" class="sc-ksYbfQ bWbAkS styled_title">
                                  XXXARTICLE_TITLE_ALTXXX
                                </h3></a>
                            </div>
                          </div>
                          <p class="sc-hqyNC hKvKBK card_body line-clamp-3">
                            XXXARTICLE_ABSTRACTXXX
                          </p>
                          <div class="sc-jbKcbu bAyEgi card_footer has-multiple-authors">
                            <div class="sc-dNLxif fJgoHP label">
                              <div title="C. Crouch" class="sc-cHGsZl eBVayB group-label left sc-TOsTZ jIPynH">
                                <img alt=" " class="sc-EHOje iiusBg group-label__icon" title="" role="presentation" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCI+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBkPSJNMTcsMjBhMSwxLDAsMCwxLTEtMVYxN2EzLDMsMCwwLDAtMy0zSDVhMywzLDAsMCwwLTMsM3YyYTEsMSwwLDAsMS0yLDBWMTdhNS4wMDYsNS4wMDYsMCwwLDEsNS01aDhhNS4wMDYsNS4wMDYsMCwwLDEsNSw1djJBMSwxLDAsMCwxLDE3LDIwWk05LDEwYTUsNSwwLDEsMSw1LTVBNS4wMDYsNS4wMDYsMCwwLDEsOSwxMFpNOSwyYTMsMywwLDEsMCwzLDNBMywzLDAsMCwwLDksMloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMgMikiLz48L2NsaXBQYXRoPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtMikiPjxwYXRoIGQ9Ik0xNywyMGExLDEsMCwwLDEtMS0xVjE3YTMsMywwLDAsMC0zLTNINWEzLDMsMCwwLDAtMywzdjJhMSwxLDAsMCwxLTIsMFYxN2E1LjAwNiw1LjAwNiwwLDAsMSw1LTVoOGE1LjAwNiw1LjAwNiwwLDAsMSw1LDV2MkExLDEsMCwwLDEsMTcsMjBaTTksMTBhNSw1LDAsMSwxLDUtNUE1LjAwNiw1LjAwNiwwLDAsMSw5LDEwWk05LDJhMywzLDAsMSwwLDMsM0EzLDMsMCwwLDAsOSwyWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMyAyKSIvPjxnIGNsaXAtcGF0aD0idXJsKCNhKSI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJyZ2JhKDAsMCwwLDApIi8+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjMDA3YjhiIi8+PC9nPjwvZz48L3N2Zz4=" height="16">XXXARTICLE_AUTHORSXXX
                              </div>
                            </div>
                            <div class="sc-jqCOkK hiWuDh label">
                              <div class="sc-cHGsZl eBVayB group-label right sc-TOsTZ cjzNEz">
                                <img alt=" " class="sc-EHOje iiusBg group-label__icon" title="" role="presentation" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTguMzMzIiBoZWlnaHQ9IjE4LjMzMyIgdmlld0JveD0iMCAwIDE4LjMzMyAxOC4zMzMiPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZD0iTTIuNjg1LDE1LjY0OEE5LjE2Niw5LjE2NiwwLDEsMSwxNS42NDgsMi42ODUsOS4xNjYsOS4xNjYsMCwxLDEsMi42ODUsMTUuNjQ4Wk0xLjY2Nyw5LjE2N2E3LjUsNy41LDAsMSwwLDcuNS03LjVBNy41MDksNy41MDksMCwwLDAsMS42NjcsOS4xNjdabTkuNDExLDMuMDg5LTIuNS0yLjVhLjgzMS44MzEsMCwwLDEtLjI0NS0uNTg5di01YS44MzMuODMzLDAsMSwxLDEuNjY3LDBWOC44MjFsMi4yNTYsMi4yNTZhLjgzMy44MzMsMCwxLDEtMS4xNzgsMS4xNzhaIi8+PC9jbGlwUGF0aD48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgY2xpcC1wYXRoPSJ1cmwoI2EpIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC44MzMgLTAuODMzKSI+PHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJyZ2JhKDAsMCwwLDApIi8+PHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjMDA3YjhiIi8+PC9nPjwvZz48L3N2Zz4=" height="16">XXXARTICLE_PUBLISH_DATEXXX
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
`;
